export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '16eb4e14-e1a4-4ef7-b340-072efbba1c05',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: 'ae03ec27-71ac-45c3-817c-4a5334edcfec',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.services4eo.com/'
};
